@import "./styles/fonts/FSEliotPro.css";
@import "./styles/fonts/PFSQuareSansPro.css";

// TODO: надо узнать откуда берется iframe
iframe {
  z-index: -10 !important;
  display: none;
}

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  overflow: overlay !important;
  font-family: "FS Elliot Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: #58637B #2E364A;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #2E364A;
  border-radius: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: #58637B;
  border-radius: 12px;
}

*::-webkit-scrollbar-button {
  display: block;
  height: 3rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-size: 9px;
  background-color: #212735;
}

@media screen and (min-width: 1000px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 1900px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 3800px) {
  html {
    font-size: 20px;
  }
}

@media screen and (min-width: 7600px) {
  html {
    font-size: 24px;
  }
}

#calendar-root-portal .calendar-popper {
  z-index: 3;
}
